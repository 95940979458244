import { fetchTokensByAddresses } from '@//utils/0x/token-registry';
import { Result, SerializableResult } from '@/types';
import { subHours } from 'date-fns';
import { promises as fs } from 'fs';
import { chunk } from 'lodash';
import { InferGetStaticPropsType } from 'next';
import { CSSProperties, useRef, useState } from 'react';
import { useIntersection, useMedia } from 'react-use';
import { Address } from 'viem';

import { fetchRecentBlogPosts } from '@/components/NewLanding/RecentBlogPosts/fetcher';
import { TrendingTokens } from '@/components/NewLanding/TrendingTokens';

import { WRAPPED_NATIVE_ADDRESSES } from '@/constants/addresses';

import { mostPopularTokensByFilterName, PopularToken, Token } from '@/data/tokens';

import { HeroColorProvider } from '@/hooks/homepage/useHeroColor';
import { useMatchaWallets } from '@/hooks/useMatchaWallets';

import { gradientTokens, tokens } from '@/styles/tokens.css';

import { trackError } from '@/utils/errors';
import { fetchTokensByAddresses as fetchTokensByAddressesSolana } from '@/utils/jupiter-tokens';
import { isTokenAddressNativeAsset } from '@/utils/multichain';

import Header from '../components/Header';
import { Footer } from '../components/NewLanding/Footer';
import { HeaderPlaceholder } from '../components/NewLanding/HeaderPlaceholder';
import { Hero } from '../components/NewLanding/Hero';
import { landingPageContentWrapperClass } from '../components/NewLanding/index.css';
import { MarketingSection } from '../components/NewLanding/MarketingSection';
import { MostPopularTokens } from '../components/NewLanding/MostPopularTokens';
import { RecentBlogPosts } from '../components/NewLanding/RecentBlogPosts';
import { RecentTrades } from '../components/NewLanding/RecentTrades';
import { TokensByChainName } from '../components/NewLanding/types';
import {
  NETWORK_NAME_PER_CHAIN_ID,
  solana,
  SUPPORTED_CHAINS,
  SUPPORTED_CHAINS_V2,
} from '../constants/chain';
import { breakpoints } from '../styles/util';
import { fetchTrendingTokens, fetchUsdPrices } from '../utils/defined';
import { isDev } from '../utils/environment';
import { ChainAddress } from '../utils/models';
import { intoSerializableResult } from '../utils/result';

const DEFINED_CHUNK_SIZE = 12; // The endpoint takes 25 inputs and we need two per token
const TRENDING_TOKEN_AMOUNT = 27; // We display 2 pages of 10 and page of 7

// A list of tokens that should not be advertised on the trending section
// as they contain inappropriate content
const BLOCKLISTED_ADDRESSES = ['9gyfbPVwwZx4y1hotNSLcqXCQNpNqqz6ZRvo8yTLpump'];

const Home = ({
  recentBlogPosts,
  recentTokenResultsMap,
  popularTokensByFilter,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { isConnected } = useMatchaWallets();
  const isMobile = useMedia(breakpoints.mobile, false);
  const [currentHeaderColor, setCurrentHeaderColor] = useState<string | null>(null);

  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    // negative top margin to trigger the intersection as soon as the header
    // passes over the token profile header
    threshold: 1.0,
  });

  // test serializing result over the wire
  return (
    <div
      data-hero-animating={currentHeaderColor !== null}
      style={
        {
          'backgroundColor': tokens.specialBackgroundApp,
          'backgroundImage': gradientTokens.specialHomepageGradient,
          '--hero-anim-color': currentHeaderColor,
        } as CSSProperties
      }
    >
      <HeroColorProvider
        value={{ currentColor: currentHeaderColor, setCurrentColor: setCurrentHeaderColor }}
      >
        <Header
          isHomepage
          intersection={intersection}
          hideLearnMoreDropdown={isMobile || isConnected}
        />
        <HeaderPlaceholder />
        <Hero ref={intersectionRef} />
        <div className={landingPageContentWrapperClass}>
          <TrendingTokens trendingTokensByFilter={recentTokenResultsMap} />
          <RecentTrades />
          <MostPopularTokens tokenResults={popularTokensByFilter} />
          <RecentBlogPosts posts={recentBlogPosts} />
          <MarketingSection />
        </div>
        <Footer />
      </HeroColorProvider>
    </div>
  );
};

export default Home;

function makeKey({ address, chainId }: PriceFetcherToken) {
  return `${address}-${chainId}`;
}

/**
 * This function fetches the current set of trending tokens for a given set of chain ids.
 * It also automatically tries to attach meta information and get the most current price.
 * In case the most current price is not available, we fall back to the price returnec from
 * defined.
 *
 * @param chainIds - The list of chainIds to retrieve the trending tokens for
 * @param filterName - The name of the current filter
 * @param fetcher - The price fetcher function
 *
 * @returns A Token list result and the filterName
 */
async function getTrendingTokensWithLogo(
  chainIds: number[],
  filterName: string,
  fetcher: TokenPriceFetcher,
): Promise<{
  result: Result<Token[]>;
  chainName: string;
}> {
  // fetch the trending tokens from defined
  const trendingTokens = await fetchTrendingTokens({
    limit: 100, // going ham with the limits to ensure we collect enough tokens to display after applying filters
    chainIds: chainIds,
    resolution: '1D',
  });

  if (trendingTokens.type === 'error') {
    // nothing we can do
    return {
      result: trendingTokens,
      chainName: filterName,
    };
  }

  const query = trendingTokens.data
    .map((result) => ({
      address: result.address as ChainAddress,
      chainId: result.networkId,
    }))
    // filter out inappropriate tokens
    .filter((token) => !BLOCKLISTED_ADDRESSES.includes(token.address));

  // get meta information from the token registry
  const tokenRegResults = await retrieveTokens(query);

  if (tokenRegResults.type === 'error') {
    // bail
    return {
      result: tokenRegResults,
      chainName: filterName,
    };
  }
  const pricingInfo = await fetcher(query, 'trending');

  const out: Token[] = [];

  for (const token of trendingTokens.data) {
    const key = makeKey({ address: token.address, chainId: token.networkId });

    const tokenRegEntry = tokenRegResults.data.find(
      (reg) => reg.address === token.address && reg.chainId === token.networkId,
    );

    const priceData = pricingInfo[key];
    const outToken: Token = {
      chainId: token.networkId,
      address: token.address as Address,
      percentageChange24:
        priceData && priceData.type === 'success'
          ? priceData.data.priceChange24
          : token.priceChange24,
      priceUSD: priceData && priceData.type === 'success' ? priceData.data.usdPrice : token.price,
    };

    // we conditionally add fields as `undefined` is not serializable
    if (tokenRegEntry?.logo) {
      outToken.logo = tokenRegEntry.logo;
    }
    if (tokenRegEntry?.name) {
      outToken.name = tokenRegEntry.name;
    }
    if (tokenRegEntry?.symbol) {
      outToken.symbol = tokenRegEntry.symbol;
    }
    if (tokenRegEntry?.color) {
      outToken.color = tokenRegEntry.color;
    }
    out.push(outToken);
  }

  return {
    result: {
      type: 'success',
      data: out.filter((value) => value.logo).slice(0, TRENDING_TOKEN_AMOUNT),
    },
    chainName: filterName,
  };
}

type PriceFetcherToken = {
  address: string;
  chainId: number;
};

type PriceData = { usdPrice: number; priceChange24: number };

export interface ChainIdToPopularTokenArray {
  chainId: string | undefined;
  tokens: PopularToken[] | undefined;
}

/**
 * Takes in an array of PopularTokens and creates a valid GetPriceInput[]
 * to fetch USD Prices from defined.fi
 * @param tokens - an array of popular tokens
 * @param filterId - Chain Id String or "All Networks"
 * @returns FetchTokenPriceDataResponseType
 */

function getPriceInputsForSingleToken(token: PriceFetcherToken) {
  const twentyFourHoursAgo = subHours(new Date(), 24);
  const twentyFourHoursAgoTimestamp = Math.floor(twentyFourHoursAgo.getTime() / 1000);
  return [
    {
      address: token.address,
      networkId: token.chainId,
    },
    {
      address: token.address,
      networkId: token.chainId,
      timestamp: twentyFourHoursAgoTimestamp,
    },
  ];
}

type TokenPriceFetcher = (
  token: PriceFetcherToken[],
  mode: 'trending' | 'popular',
) => Promise<Record<string, Result<PriceData>>>;
type TokenWithoutPrice = Omit<Token, 'priceUSD' | 'percentageChange24'>;

/**
 * Fetches a list of tokens from tokenRegistry
 *
 * @param address - The address of the token
 * @param chainId - The chainId of the token
 *
 * @returns The token info with MetaInfo and pricing
 */
async function retrieveTokens(
  tokens: { address: ChainAddress; chainId: number }[],
): Promise<Result<TokenWithoutPrice[]>> {
  const solanaTokens = tokens.filter((token) => token.chainId === solana.id);
  const ethereumTokens = tokens.filter((token) => token.chainId !== solana.id);

  const ethereumAddresses = new Set<ChainAddress>();
  const evmChains = new Set<number>();
  for (const token of ethereumTokens) {
    ethereumAddresses.add(token.address);
    evmChains.add(token.chainId);
  }
  // first we fetch the registry information (name, symbol, logo)
  const registryResult = await fetchTokensByAddresses({
    addresses: Array.from(ethereumAddresses),
    chainId: Array.from(evmChains),
  });
  if (registryResult.type === 'error') {
    // The request to the token registry can not partially fail
    // in case we have an error when fetching info from the token registry,
    // we fail the whole chunk

    return registryResult;
  }

  const registryResultSolana = await fetchTokensByAddressesSolana({
    addresses: solanaTokens.map((token) => token.address),
  });

  if (registryResultSolana.type === 'error') {
    return registryResultSolana;
  }

  const list = [...registryResultSolana.data.data, ...registryResult.data.data];

  return {
    type: 'success',
    data: list.map((token) => {
      const out = {
        address: token.address,
        chainId: token.chainId,
        symbol: token.symbol,
        name: token.name,
      } as TokenWithoutPrice;
      if (token.logo) {
        out.logo = token.logo;
      }
      if (token.prominentColor) {
        out.color = token.prominentColor;
      }
      return out;
    }),
  };
}

/**
 * This function loads a set of popular tokens for a certain filter name
 * The filter name is a selector for the predefined list of tokens in {@link mostPopularTokensByFilterName}
 *
 * @param filterName - the filter to select
 * @param fetcher - the price fetcher function
 */
async function loadPopularTokensByFilter(
  filterName: string,
  fetcher: TokenPriceFetcher,
): Promise<SerializableResult<Token[]>> {
  const query = mostPopularTokensByFilterName[filterName].map((token) => ({
    address: token.address as Address,
    chainId: token.chainId,
  }));
  const tokens = await retrieveTokens(query);
  if (tokens.type === 'error') {
    // in case we have an error from the token registry, there is nothing we can do
    return intoSerializableResult(tokens);
  }

  // load the price information from defined
  const priceRes = await fetcher(query, 'popular');

  const out: Token[] = [];

  for (const token of query) {
    const key = makeKey(token);
    const tokenRegEntry = tokens.data.find(
      (entry) => entry.address === token.address && entry.chainId === token.chainId,
    );

    // each entry in the pricing map is encoded like <address>-<chainId>
    const priceEntry = priceRes[key];
    if (!priceEntry || priceEntry.type === 'error') {
      console.error(`Missing price entry for ${token.address} on ${token.chainId}`);
      trackError(new Error('Missing price entry'), token);
      // for the popular tokens, we don't have a price fallback so we gtfo
      return {
        type: 'error',
        error: 'Missing price entry',
      };
    }
    const outToken: Token = {
      address: token.address,
      chainId: token.chainId,
      priceUSD: priceEntry.data.usdPrice,
      percentageChange24: priceEntry.data.priceChange24,
    };
    // adding these fields conditionally as `undefined` is not serializable
    if (tokenRegEntry?.logo) {
      outToken.logo = tokenRegEntry.logo;
    }
    if (tokenRegEntry?.name) {
      outToken.name = tokenRegEntry.name;
    }
    if (tokenRegEntry?.symbol) {
      outToken.symbol = tokenRegEntry.symbol;
    }
    if (tokenRegEntry?.color) {
      outToken.color = tokenRegEntry.color;
    }
    out.push(outToken);
  }

  return {
    type: 'success',
    data: out,
  };
}

/**
 * Fetches all token information for popular tokens
 * @param fetcher - An instance of TokenPriceFetcher
 *
 * @returns The token results per filter
 */
async function loadPopularTokens(
  fetcher: TokenPriceFetcher,
): Promise<Record<string, SerializableResult<Token[]>>> {
  const out: Record<string, SerializableResult<Token[]>> = {};

  // we first load popular tokens per each chain individually
  for (const { id } of SUPPORTED_CHAINS_V2) {
    const name = NETWORK_NAME_PER_CHAIN_ID[id];
    const result = await loadPopularTokensByFilter(name, fetcher);
    out[name] = result;
  }
  // and then for all networks
  const tokens = await loadPopularTokensByFilter('All networks', fetcher);
  out['All networks'] = tokens;
  return out;
}

export async function getStaticProps() {
  if (isDev && process.env.FORCE_MOCK_REFRESH !== 'true') {
    const mockedProps = JSON.parse(
      (await fs.readFile('./src/data/mocks/homepage.json')).toString(),
    );
    console.log('on dev environment: loaded mocked props');
    return {
      props: mockedProps,

      revalidate: 60 * 10, // 10 minutes
    };
  }
  const tokenMap = new Map<string, PriceData>();

  // we need to create the function here, as it is not stripped from the bundle otherwise
  async function tokenFetcher(
    tokens: PriceFetcherToken[],
    mode: 'trending' | 'popular',
  ): Promise<Record<string, Result<PriceData>>> {
    // process chunks
    const staged: PriceFetcherToken[] = [];
    const queriedAddressToAddress: Record<string, string> = {};
    const out: Record<string, Result<PriceData>> = {};

    for (const token of tokens) {
      token.address = token.address.toLowerCase();

      const key = makeKey(token);
      if (tokenMap.has(key)) {
        // casting to result type as we have already established that the key exists
        out[key] = { type: 'success', data: tokenMap.get(key)! };
        continue;
      }
      // if we didn't query that token yet, let's stage it for querying
      staged.push(token);
    }

    // all tokens were already known
    if (staged.length === 0) return out;

    // Defined truncates all inputs over the input limit
    const chunks = chunk(tokens, DEFINED_CHUNK_SIZE);

    for (const chunk of chunks) {
      const inputs: ReturnType<typeof getPriceInputsForSingleToken>[] = [];
      for (const token of chunk) {
        const key = makeKey(token);

        // for native tokens we need to query the wrapped native
        const addressToQuery = isTokenAddressNativeAsset(token.address, token.chainId)
          ? WRAPPED_NATIVE_ADDRESSES[token.chainId]
          : (token.address as Address);

        if (!addressToQuery) {
          console.error(
            `Error when retrieving pricing information: Unmapped native address for ${token.address} on ${token.chainId}`,
          );
          const err = new Error('Unmapped native address');
          // in trending tokens mode we will often run into missing price information, we want to silence them
          if (mode === 'popular') {
            trackError(err, token);
          }
          out[key] = { type: 'error', error: err };
          continue;
        }

        const queriedKey = makeKey({ address: addressToQuery, chainId: token.chainId });
        // we build a lookup table from <queriedAddress>-<chainId> => <tokenAddress>
        queriedAddressToAddress[queriedKey] = token.address;
        inputs.push(getPriceInputsForSingleToken({ ...token, address: addressToQuery }));
      }

      // fetch the price data from defined
      const priceRes = await fetchUsdPrices(inputs.flat());

      if (priceRes.type === 'error') {
        // if we can't get price, we will fail all tokens
        for (const token of staged) {
          out[makeKey(token)] = priceRes;
        }
        return out;
      }

      for (const token of chunk) {
        const key = makeKey(token);
        // we search for all entries that either match the current tokens address and chainId
        // or their wrapped address and chainId in case of native tokens
        const resForToken = priceRes.data.filter(
          (price) =>
            price &&
            (queriedAddressToAddress[
              makeKey({
                address: price.address,
                chainId: token.chainId,
              })
            ] === token.address ||
              price.address === token.address) &&
            price.networkId === token.chainId,
        ) as Exclude<(typeof priceRes.data)[number], null>[];

        if (resForToken.length < 2) {
          const err = new Error('Insufficient data');

          // in trending tokens mode we will often run into missing price information, we want to silence them
          if (mode === 'popular') {
            console.error(
              `Error when retrieving pricing info for ${token.address} on ${token.chainId}: Insufficient Prices: ${resForToken.length}`,
              resForToken,
            );
            trackError(err, token);
          }
          // we need at least two entries to build the change percentage
          out[key] = {
            type: 'error',
            error: err,
          };
          continue;
        }
        // we don't trust the order so we sort chronologically descending
        resForToken.sort((a, b) => b.timestamp - a.timestamp);

        const now = resForToken[0];
        const anHourAgo = resForToken[resForToken.length - 1];

        if (now.timestamp === anHourAgo.timestamp) {
          const err = new Error('Insufficient data');
          // in trending tokens mode we will often run into missing price information, we want to silence them
          if (mode === 'popular') {
            console.error(
              `Error when retrieving pricing info for ${token.address} on ${token.chainId}: Matching timestamps`,
            );
            trackError(err, token);
          }
          out[key] = {
            type: 'error',
            error: err,
          };
          continue;
        }

        const usdPrice = now.priceUsd;
        const priceChange24 = (now.priceUsd - anHourAgo.priceUsd) / anHourAgo.priceUsd;

        const price = {
          usdPrice,
          priceChange24,
        };

        // store the pricing information for later querying
        tokenMap.set(key, price);

        out[key] = {
          type: 'success',
          data: price,
        };
      }
    }

    return out;
  }

  const recentTokenResults = await Promise.all([
    ...SUPPORTED_CHAINS.map(async (chain) =>
      getTrendingTokensWithLogo([chain.id], NETWORK_NAME_PER_CHAIN_ID[chain.id], tokenFetcher),
    ),
    getTrendingTokensWithLogo([solana.id], solana.name, tokenFetcher),
  ]);

  recentTokenResults.unshift(
    await getTrendingTokensWithLogo(
      SUPPORTED_CHAINS_V2.map((chain) => chain.id),
      'All networks',
      tokenFetcher,
    ),
  );

  const recentTokenResultsMap = recentTokenResults.reduce<TokensByChainName>((acc, curr) => {
    acc[curr.chainName] = intoSerializableResult(curr.result);
    return acc;
  }, {});

  const popularTokens = await loadPopularTokens(tokenFetcher);

  const recentBlogPosts = intoSerializableResult(await fetchRecentBlogPosts());

  if (isDev && process.env.FORCE_MOCK_REFRESH === 'true') {
    await fs.writeFile(
      './src/data/mocks/homepage.json',
      JSON.stringify(
        { recentBlogPosts, recentTokenResultsMap, popularTokensByFilter: popularTokens },
        null,
        2,
      ),
    );
  }

  return {
    props: {
      recentBlogPosts,
      recentTokenResultsMap,
      popularTokensByFilter: popularTokens,
    },

    revalidate: 60 * 10, // 10 minutes
  };
}
